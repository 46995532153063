<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0" style="overflow: hidden">
      <!-- Brand logo-->
      <b-link href="https://automatumcloud.com" class="brand-logo">
        <b-img :src="automatumlogo" height="64px" alt="Automatum Logo"></b-img>
        <!--
          <h2 class="brand-text text-primary ml-1" style="margin-top: 0.6rem">
          Automatum
        </h2>
        -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          style="height: 700px"
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Register V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1"> Adventure starts here 🚀 </b-card-title>
          <b-card-text class="mb-2">
            Make your app management easy and fun!
          </b-card-text>

          <b-alert v-if="error" variant="danger" show>
            <div class="alert-body">
              <span>{{ error }}</span>
            </div>
          </b-alert>

          <!-- form -->
          <validation-observer ref="registerForm" #default="{ invalid }">
            <b-form class="auth-register-form mt-2" @submit.prevent="register">
              <!-- username -->
              <b-form-group label="Name" label-for="register-username">
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="register-name"
                    v-model="username"
                    name="register-name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group label="Email" label-for="register-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group label-for="register-password" label="Password">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

            <!--
                <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="terms__status"
                  name="checkbox-1"
                >
                  I agree to
                  <b-link href="/" target="_blank"
                    >privacy policy & terms</b-link
                  >
                </b-form-checkbox>
              </b-form-group>
            -->

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid"
              >
                <div
                  v-if="isLoading"
                  class="d-flex align-items-center justify-content-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    class="spinner"
                    style="fill: white; transform: ; msfilter: "
                  >
                    <path
                      d="M12 22c5.421 0 10-4.579 10-10h-2c0 4.337-3.663 8-8 8s-8-3.663-8-8c0-4.336 3.663-8 8-8V2C6.579 2 2 6.58 2 12c0 5.421 4.579 10 10 10z"
                    ></path>
                  </svg>
                  <div class="loader" style="margin-left: 3px">Creating...</div>
                </div>
                <div v-else>Sign up</div>
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Already have an account?</span>
            <b-link :to="{ name: 'auth-login' }">
              <span>&nbsp;Sign in instead</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BAlert,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { signUp } from "@/auth/amplify/amplifyService";
import { createUser, uuid } from "@/aws/Api";
import { encryptItem } from "@/auth/crypto";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BAlert,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      username: "",
      userEmail: "",
      password: "",
      isLoading: false,
      error: null,
      sideImg: require("@/assets/images/automatum/register.png"),
      automatumlogo: require("@/assets/images/automatum/logo-1.png"),
      // validation
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    register() {
      this.$refs.registerForm.validate().then(async (success) => {
        if (success) {
          try {
          
            this.isLoading = true;
            // you will not we are using email on the username and email
            // this is because we choose to setup Cognito and email was the choice
            // of signing
            // admin => {action: 'manage', subject: 'all'}
            await signUp(this.userEmail, this.password, this.userEmail);
            await createUser({
              business_id: "5a25a404-1d50-4134-aa66-f9d0e5622ab3",
              username: this.username,
              user__role: "customer",
              user__id: uuid(),
              email: this.userEmail,
              ability: encryptItem([
                { action: "read", subject: "ACL" },
                { action: "read", subject: "Shared" },
                { action: "read", subject: "Auth" },
              ]),
            });
            // users information can be created in Postgre Database
            // reset state
            this.isLoading = false;
            this.$router.replace("/login").then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Welcome to Automatum`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `You have successfully logged in as ${this.userEmail}. Now you can login on Automatum and explore!`,
                },
              });
            });
          } catch (error) {
            this.error = error.message;
            this.isLoading = false;
          }
        }
      });
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.spinner {
  animation: spin 1s linear infinite;
}

.loader {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
