var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0",staticStyle:{"overflow":"hidden"}},[_c('b-link',{staticClass:"brand-logo",attrs:{"href":"https://automatumcloud.com"}},[_c('b-img',{attrs:{"src":_vm.automatumlogo,"height":"64px","alt":"Automatum Logo"}})],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5",staticStyle:{"height":"700px"}},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Adventure starts here 🚀 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Make your app management easy and fun! ")]),(_vm.error)?_c('b-alert',{attrs:{"variant":"danger","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v(_vm._s(_vm.error))])])]):_vm._e(),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"register-username"}},[_c('validation-provider',{attrs:{"name":"Name","vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-name","name":"register-name","state":errors.length > 0 ? false : null,"placeholder":"Name"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false : null,"placeholder":"Email"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":"Password"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid}},[(_vm.isLoading)?_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('svg',{staticClass:"spinner",staticStyle:{"fill":"white","transform":"","msfilter":""},attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M12 22c5.421 0 10-4.579 10-10h-2c0 4.337-3.663 8-8 8s-8-3.663-8-8c0-4.336 3.663-8 8-8V2C6.579 2 2 6.58 2 12c0 5.421 4.579 10 10 10z"}})]),_c('div',{staticClass:"loader",staticStyle:{"margin-left":"3px"}},[_vm._v("Creating...")])]):_c('div',[_vm._v("Sign up")])])],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already have an account?")]),_c('b-link',{attrs:{"to":{ name: 'auth-login' }}},[_c('span',[_vm._v(" Sign in instead")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }